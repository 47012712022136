.App {
  /* min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center; */
}

.adjustWallet{
  height: 30px !important;
  align-self: center !important;
  text-align: center !important;
  align-items: center !important;
}
.whiteH{
  color: aliceblue;
}
body{
  background-color: #333;
}
.money{

  color: #9efa34;
}
.ul_coins{
  font-size: 17px;
}

.info-text {
  background-color: #f2f4f8; /* Light grey background for slight emphasis */
  color: #333; /* Dark grey text for high contrast and readability */
  font-family: 'Arial', sans-serif; /* Sets a readable, web-safe font */
  padding: 20px; /* Adds space inside the div */
  border-radius: 8px; /* Rounded corners */
  box-shadow: 0 2px 4px rgba(0,0,0,0.1); /* Subtle shadow for a lifted effect */
  margin: 20px 0; /* Adds vertical spacing around the div */
  line-height: 1.6; /* Increases line height for better readability */
  font-size: 16px; /* Sets an appropriate font size for information text */
}

.queenCoin{
  background-color: rgb(255, 130, 108);
}



.grid-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr); /* Three columns on desktop */
  gap: 20px; /* Space between items */
  padding: 20px; /* Padding around the grid */
}

/* Media query for tablet screens - max-width can vary based on your design requirements */
@media (max-width: 768px) {
  .grid-container {
    grid-template-columns: repeat(2, 1fr); /* Two columns on tablet */
  }
}

/* Media query for mobile screens */
@media (max-width: 580px) {
  .grid-container {
    grid-template-columns: 1fr; /* One column on mobile */
  }
}


.rowContainer {
  display: flex;
  align-items: center;
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.rowImage {
  width: 90px;
  height: 90px;
  margin-right: 20px;
}

.rowContent {
  display: flex;
  flex-direction: column;
}

.rowTitle {
  margin: 0 0 5px 0;
}

.rowLink {
  color: #0077cc;
  text-decoration: none;
}
